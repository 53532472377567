import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL, API_UPLOAD } from "../../config/index";
import { Icon } from "@iconify-icon/react";
import ErrorAccess from "../ErrorPage/ErrorAccess";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestDelete, sendRequestGet } from "../../swr";
import Loading from "../Loading";
import TableGrid from "../../components/TableGrid";
import Badge from "../../components/Badge";
import ModalImage from "react-modal-image";

const Table = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemPage, setItemPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [field, setField] = useState("title");
  const [fieldQuery, setFieldQuery] = useState("title");
  const [keyword, setKeyword] = useState("");
  const [keywordQuery, setKeywordQuery] = useState("");

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/slider`,
    sendRequestDelete
  );

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL}/slider?page=${page}&limit=${limit}`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await trigger({ id: id });

          mutate();

          Swal.fire("Deleted!", result.message, "success");
        } catch (e) {
          Swal.fire("Deleted!", e.info.message, "error");
        }
      }
    });
  };

  const changePage = ({ selected }) => {
    setItemPage(selected);
    setPage(selected + 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeywordQuery(keyword);
    setFieldQuery(field);
  };

  const resetPage = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeyword("");
    setKeywordQuery("");
    setField("title");
    setFieldQuery("title");
    setLimit(10);
  };

  if (isLoading || isMutating) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <TableGrid
      search={{
        itemField: [
          {
            title: "Title",
            value: "title",
          },
        ],
        setField: setField,
        field: field,
        keyword: keyword,
        setKeyword: setKeyword,
        submit: searchData,
      }}
      toolbox={{
        reset: resetPage,
        add: "add",
      }}
      columns={[
        "#",
        "FOTO",
        "TITLE",
        "STATUS POST",
        "DESKRIPSI",
        "CREATED AT",
        "UPDATED AT",
        "FOTO",
        "EDIT",
        "HAPUS",
      ]}
      data={data.docs.map((val) => {
        return [
          val._id,
          <ModalImage
            small={`${API_UPLOAD}/slider/resize/x128/${val.fotoSlider}`}
            large={`${API_UPLOAD}/slider/real/${val.fotoSlider}`}
            alt={val.title}
            hideZoom={true}
            showRotate={true}
            className="w-[32px] h-[32px] rounded-lg drop-shadow hover:drop-shadow-lg"
          />,
          val.title,
          <Badge
            text={val.statusSlider === "Ya" ? "Ya" : "Tidak"}
            tipe={val.statusSlider === "Ya" ? "green" : "warning"}
          />,
          val.description,
          val.createdAt,
          val.updatedAt,
          <Link to={`foto/${val._id}`}>
            <Icon
              icon="solar:camera-add-bold-duotone"
              style={{ fontSize: "21px", color: "#ea580c" }}
            />
          </Link>,
          <Link to={`edit/${val._id}`}>
            <Icon
              icon="solar:pen-new-round-bold-duotone"
              style={{ fontSize: "21px", color: "#65a30d" }}
            />
          </Link>,
          <button type="button" onClick={() => deleteData(val._id)}>
            <Icon
              icon="solar:trash-bin-trash-bold-duotone"
              style={{ fontSize: "21px", color: "#e11d48" }}
            />
          </button>,
        ];
      })}
      totalDocs={data.totalDocs}
      totalPages={data.totalPages}
      page={itemPage}
      limit={limit}
      setLimit={setLimit}
      changePage={changePage}
    />
  );
};

export default Table;
