import Root from "./Root";
import Login from "../pages/Login";

import Home from "../pages/Home";

import Kategori from "../pages/Kategori";
import TableKategori from "../pages/Kategori/Table";
import FormAddKategori from "../pages/Kategori/FormAdd";
import FormEditKategori from "../pages/Kategori/FormEdit";

import Page from "../pages/Page";
import TablePage from "../pages/Page/Table";
import FormAddPage from "../pages/Page/FormAdd";
import FormEditPage from "../pages/Page/FormEdit";

import Editor from "../pages/Editor";
import TableEditor from "../pages/Editor/Table";
import FormAddEditor from "../pages/Editor/FormAdd";
import FormEditEditor from "../pages/Editor/FormEdit";
import FormFotoEditor from "../pages/Editor/FormFoto";

import Berita from "../pages/Berita";
import TableBerita from "../pages/Berita/Table";
import FormAddBerita from "../pages/Berita/FormAdd";
import FormEditBerita from "../pages/Berita/FormEdit";
import AddEditor from "../pages/Berita/AddEditor";
import FormFotoBerita from "../pages/Berita/FormFoto";

import BeritaVideo from "../pages/BeritaVideo";
import TableBeritaVideo from "../pages/BeritaVideo/Table";
import FormAddBeritaVideo from "../pages/BeritaVideo/FormAdd";
import FormEditBeritaVideo from "../pages/BeritaVideo/FormEdit";
import AddEditorBeritaVideo from "../pages/BeritaVideo/AddEditor";
import FormFotoBeritaVideo from "../pages/BeritaVideo/FormFoto";

import Slider from "../pages/Slider";
import TableSlider from "../pages/Slider/Table";
import FormAddSlider from "../pages/Slider/FormAdd";
import FormEditSlider from "../pages/Slider/FormEdit";
import FormFotoSlider from "../pages/Slider/FormFoto";

import User from "../pages/User";
import TableUser from "../pages/User/Table";
import FormAddUser from "../pages/User/FormAdd";
import FormEditUser from "../pages/User/FormEdit";
import FormPassword from "../pages/User/FormPassword";

import Visitor from "../pages/Visitor";
import TableVisitor from "../pages/Visitor/Table";
import FormAddVisitor from "../pages/Visitor/FormAdd";
import FormEditVisitor from "../pages/Visitor/FormEdit";

import ErrorPage from "../pages/ErrorPage";

export const Paths = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },

      {
        path: "kategori",
        element: <Kategori />,
        breadcrumb: "Kategori",
        children: [
          {
            index: true,
            element: <TableKategori />,
            breadcrumb: "Kategori",
          },
          {
            path: "add",
            element: <FormAddKategori />,
            breadcrumb: "Tambah Kategori",
          },
          {
            path: "edit/:id",
            element: <FormEditKategori />,
            breadcrumb: "Edit Kategori",
          },
        ],
      },

      {
        path: "page",
        element: <Page />,
        breadcrumb: "Page",
        children: [
          {
            index: true,
            element: <TablePage />,
            breadcrumb: "Page",
          },
          {
            path: "add",
            element: <FormAddPage />,
            breadcrumb: "Tambah Page",
          },
          {
            path: "edit/:id",
            element: <FormEditPage />,
            breadcrumb: "Edit Page",
          },
        ],
      },

      {
        path: "editor",
        element: <Editor />,
        breadcrumb: "Editor",
        children: [
          {
            index: true,
            element: <TableEditor />,
            breadcrumb: "Editor",
          },
          {
            path: "add",
            element: <FormAddEditor />,
            breadcrumb: "Tambah Editor",
          },
          {
            path: "edit/:id",
            element: <FormEditEditor />,
            breadcrumb: "Edit Editor",
          },
          {
            path: "foto/:id",
            element: <FormFotoEditor />,
            breadcrumb: "Edit Foto",
          },
        ],
      },

      {
        path: "berita",
        element: <Berita />,
        breadcrumb: "Berita",
        children: [
          {
            index: true,
            element: <TableBerita />,
            breadcrumb: "Berita",
          },
          {
            path: "add",
            element: <FormAddBerita />,
            breadcrumb: "Tambah Berita",
          },
          {
            path: "edit/:id",
            element: <FormEditBerita />,
            breadcrumb: "Edit Berita",
          },
          {
            path: "editor/:id",
            element: <AddEditor />,
            breadcrumb: "Tambah Editor",
          },
          {
            path: "foto/:id",
            element: <FormFotoBerita />,
            breadcrumb: "Edit Foto",
          },
        ],
      },

      {
        path: "berita-video",
        element: <BeritaVideo />,
        breadcrumb: "Berita Video",
        children: [
          {
            index: true,
            element: <TableBeritaVideo />,
            breadcrumb: "Berita Video",
          },
          {
            path: "add",
            element: <FormAddBeritaVideo />,
            breadcrumb: "Tambah Berita Video",
          },
          {
            path: "edit/:id",
            element: <FormEditBeritaVideo />,
            breadcrumb: "Edit Berita",
          },
          {
            path: "editor/:id",
            element: <AddEditorBeritaVideo />,
            breadcrumb: "Tambah Editor Berita",
          },
          {
            path: "foto/:id",
            element: <FormFotoBeritaVideo />,
            breadcrumb: "Edit Foto",
          },
        ],
      },

      {
        path: "slider",
        element: <Slider />,
        breadcrumb: "Slider",
        children: [
          {
            index: true,
            element: <TableSlider />,
            breadcrumb: "Slider",
          },
          {
            path: "add",
            element: <FormAddSlider />,
            breadcrumb: "Tambah Slider",
          },
          {
            path: "edit/:id",
            element: <FormEditSlider />,
            breadcrumb: "Edit Slider",
          },
          {
            path: "foto/:id",
            element: <FormFotoSlider />,
            breadcrumb: "Edit Slider",
          },
        ],
      },

      {
        path: "user",
        element: <User />,
        breadcrumb: "User",
        children: [
          { index: true, element: <TableUser />, breadcrumb: "User" },
          {
            path: "add",
            element: <FormAddUser />,
            breadcrumb: "Tambah User",
          },
          {
            path: "edit/:id",
            element: <FormEditUser />,
            breadcrumb: "Edit User",
          },
          {
            path: "password/:id",
            element: <FormPassword />,
            breadcrumb: "Edit Password",
          },
        ],
      },

      {
        path: "visitor",
        element: <Visitor />,
        breadcrumb: "Visitor",
        children: [
          {
            index: true,
            element: <TableVisitor />,
            breadcrumb: "Visitor",
          },
          {
            path: "add",
            element: <FormAddVisitor />,
            breadcrumb: "Tambah Visitor",
          },
          {
            path: "edit/:id",
            element: <FormEditVisitor />,
            breadcrumb: "Edit Visitor",
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
];
