import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL } from "../../config/index";
import { useNavigate } from "react-router-dom";
import { HiOutlineSave, HiOutlineArrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWRMutation from "swr/mutation";
import { sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";

const FormAdd = () => {
  const [namaKategori, setNamaKategori] = useState("");
  const [slugKategori, setSlugKategori] = useState("");
  const [descKategori, setDescKategori] = useState("");

  const navigate = useNavigate();

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/kategori`,
    sendRequestPostWithToken
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        namaKategori: namaKategori,
        slugKategori: slugKategori,
        descKategori: descKategori,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating) return <Loading />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Tambah Kategori Baru
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Penambahan kategori, isi informasi data dibawah ini.
        </p>
      </div>
      <div className="grid grid-cols-6 py-6">
        <form
          onSubmit={simpan}
          className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
        >
          <div className="border-b border-gray-300 pb-2 px-4">
            <h2 className="font-sans font-bold text-lg">Detail Kategori</h2>
            <p className="font-sans font-normal text-sm text-gray-400">
              Nama kategori, slug, deskripsi
            </p>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
            <div className="sm:col-start-1 sm:col-span-2">
              <InputText
                label="Nama Kategori"
                name="namaKategori"
                val={namaKategori}
                set={setNamaKategori}
                placeholder="Masukkan nama kategori"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-4">
              <InputText
                label="Slug Kategori"
                name="slugKategori"
                val={slugKategori}
                set={setSlugKategori}
                placeholder="Masukkan slug kategori"
              />
              <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
                Perhatikan untuk input slug karena menampilkan menu
              </p>
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <InputText
                label="Deskripsi Kategori"
                name="descKategori"
                val={descKategori}
                set={setDescKategori}
                placeholder="Masukkan deskripsi kategori"
              />
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiOutlineArrowLeft size={18} />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiOutlineSave size={18} />
              </span>
              <span>Simpan Kategori</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAdd;
