import React, { useState, useEffect, useRef } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_UPLOAD, API_URL } from "../../config/index";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  HiOutlineCloudUpload,
  HiOutlineSave,
  HiOutlineArrowLeft,
} from "react-icons/hi";
import { sendRequesPostImageWithToken, sendRequestPut } from "../../swr";
import useSWRMutation from "swr/mutation";
import Loading from "../Loading";

const FormFoto = () => {
  const { id } = useParams();
  const [fotoBerita, setFotoBerita] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);

  const inputFile = useRef(null);

  const navigate = useNavigate();

  const { trigger: triggerUpload, isMutatingUpload } = useSWRMutation(
    `${API_UPLOAD}/upload`,
    sendRequesPostImageWithToken
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/berita/${id}`,
    sendRequestPut
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const onButtonUpload = () => {
    inputFile.current.click();
  };

  const handleChange = (e) => {
    if (e.target.files) {
      setFotoBerita(e.target.files[0]);
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    const fileName = new Date().getTime();

    const formData = new FormData();
    // Add necessary form data
    formData.append("file", fotoBerita);
    formData.append("fileName", fileName);
    formData.append("folder", "berita");
    formData.append("x64", "ya");

    try {
      const resultUpload = await triggerUpload(formData);

      const result = await trigger({
        fotoBerita: resultUpload.fileName,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating || isMutatingUpload) return <Loading />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Edit Foto Berita Baru
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Perubahan foto berita , update foto berita dibawah ini.
        </p>
      </div>
      <div className="grid grid-cols-6 py-6">
        <form
          onSubmit={simpan}
          className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
        >
          <input
            type="file"
            id="file"
            ref={inputFile}
            className="hidden"
            onChange={handleChange}
          />
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
            <div className="sm:col-start-1 sm:col-span-6">
              <div className="px-4 py-6 border border-gray-200 rounded-lg cursor-pointer bg-gray-100">
                {!fotoBerita ? (
                  <div onClick={onButtonUpload}>
                    <div className="flex items-center justify-center mb-4">
                      <HiOutlineCloudUpload size={45} color="#94a3b8" />
                    </div>
                    <p className="font-poppins-semibold text-md text-black text-center">
                      Klik disini untuk mengambil gambar
                    </p>
                    <p className="font-poppins-light text-sm text-gray-400 text-center">
                      Format file yang diperbolehkan: .jpg, .jpeg, .png, .pdf
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div className="flex-1">{fotoBerita.name}</div>
                    <div className="flex flex-row space-x-4">
                      <Link
                        to={previewImg}
                        target="_blank"
                        className="px-4 py-2 bg-blue-600 rounded-lg shadow-md hover:bg-blue-400"
                      >
                        <span className="text-white font-poppins-regular text-sm">
                          Preview
                        </span>
                      </Link>
                      <button
                        type="button"
                        className="px-4 py-2 bg-rose-600 rounded-lg shadow-md hover:bg-rose-400"
                        onClick={() => setFotoBerita(null)}
                      >
                        <span className="text-white font-poppins-regular text-sm">
                          Hapus
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiOutlineArrowLeft size={18} />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiOutlineSave size={18} />
              </span>
              <span>Simpan Berita</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormFoto;
