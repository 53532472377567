import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL, API_UPLOAD } from "../../config/index";
import { Icon } from "@iconify-icon/react";
import { Link, useNavigate, useParams } from "react-router-dom";

import SelectEditor from "../../components/Form2/SelectEditor";
import InputText from "../../components/Form2/InputText";
import ChooseCard from "../../components/ChooseCard";

import { FaSort } from "react-icons/fa";
import { BsFillTrashFill } from "react-icons/bs";
import {
  HiSave,
  HiArrowNarrowLeft,
  HiOutlineSave,
  HiOutlineArrowLeft,
} from "react-icons/hi";
import useSWR from "swr";
import {
  sendRequestDelete,
  sendRequestGet,
  sendRequestPostWithToken,
} from "../../swr";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import useSWRMutation from "swr/mutation";
import Loading from "../Loading";
import ModalImage from "react-modal-image";
import TableGrid from "../../components/TableGrid";
import Badge from "../../components/Badge";

const AddEditor = () => {
  const { id } = useParams();

  const [editors, setEditors] = useState([]);
  const [editorteams, setEditorTeams] = useState([]);

  const [title, setTitle] = useState("");

  const [idEditor, setIdEditor] = useState("");

  const [namaLengkap, setNamaLengkap] = useState("");
  const [initialName, setInitialName] = useState("");

  const [group, setGroup] = useState("");
  const [fotoProfile, setFotoProfile] = useState("");
  const [slugPenulis, setSlugPenulis] = useState("");

  const navigate = useNavigate();

  // menampilkan data editor yang diselect pada tabel
  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/video/editor/${id}`,
    sendRequestPostWithToken
  );

  const { trigger: triggerDelete, isMutatingDelete } = useSWRMutation(
    `${API_URL}/video/editor/${id}`,
    sendRequestDelete
  );

  // ini yang bagian form atas
  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL}/video/${id}`,
    sendRequestGet
  );

  // ini untuk memilih select editor form atas
  const {
    data: dataEditor,
    error: errorEditor,
    isLoadingEditor,
  } = useSWR(`${API_URL}/editor-select`, sendRequestGet);

  // get editor
  useEffect(() => {
    // get refresh token
    checkToken();

    if (!isLoading && data) {
      setTitle(data.title);
      setEditorTeams(data.editorteams);
    }

    if (!isLoadingEditor && dataEditor) {
      setIdEditor(dataEditor.idEditor);
      setNamaLengkap(dataEditor.namaLengkap);
      setGroup(dataEditor.group);
      setFotoProfile(dataEditor.fotoProfile);
      setInitialName(dataEditor.initialName);
      setSlugPenulis(dataEditor.slugPenulis);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, dataEditor, isLoadingEditor]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const handleSetIdEditor = (event) => {
    const selectedId = event.target.value;
    setIdEditor(selectedId); // Gunakan setIdEditor untuk memperbarui state

    // Cari editor berdasarkan ID yang dipilih
    const selectedEditor = dataEditor?.find(
      (editor) => editor._id === selectedId
    );
    if (selectedEditor) {
      setNamaLengkap(selectedEditor.namaLengkap || "");
      setInitialName(selectedEditor.initialName || "");
      setGroup(selectedEditor.group);
    } else {
      setNamaLengkap("");
      setInitialName("");
      setGroup("");
    }
  };

  const checkGroup = (selected) => {
    setGroup(selected);
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        editors: editors,
        editorteams: editorteams,
        title: title,
        idEditor: idEditor,
        namaLengkap: namaLengkap,
        group: group,
        fotoProfile: fotoProfile,
        initialName: initialName,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate();
          window.location.reload();
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await triggerDelete({ id: id });

          mutate();

          Swal.fire("Deleted!", result.message, "success");
        } catch (e) {
          Swal.fire("Deleted!", e.info.message, "error");
        }
      }
    });
  };

  if (error || errorEditor) return <ErrorAccess />;
  if (isMutating || isMutatingDelete) return <Loading />;

  return (
    <>
      <div className="px-10 mb-10">
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Tambah Tim Editor Video
        </h2>
        <p className="text-sm font-sans-regular text-gray-400">
          Judul Berita Video:
          <span className="text-black font-sans font-bold">{title}</span>
        </p>
        <div className="grid grid-cols-6 py-6">
          <form
            onSubmit={simpan}
            className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
          >
            <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
              <div className=" sm:col-span-6">
                <SelectEditor
                  label="Editor Team"
                  name="Editor"
                  val={idEditor}
                  set={handleSetIdEditor}
                  item={dataEditor || []}
                />
              </div>
              <div className="sm:col-start-1 sm:col-span-4">
                <InputText
                  label="Nama Lengkap"
                  name="namaLengkap"
                  val={namaLengkap || ""}
                  set={setNamaLengkap}
                  placeholder="Masukkan nama lengkap"
                />
              </div>
              <div className="sm:col-span-2">
                <InputText
                  label="Inisial"
                  name="initialName"
                  val={initialName || ""}
                  set={setInitialName}
                  placeholder="Masukkan inisial"
                />
              </div>
              <div className="sm:col-start-1 sm:col-span-3">
                <ChooseCard
                  name={group}
                  key="Editor"
                  value="Editor"
                  label="Editor"
                  desc="Pilih ini jika penulis seorang Editor"
                  onClick={() => checkGroup("Editor")}
                />
              </div>
              <div className="sm:col-span-3">
                <ChooseCard
                  name={group}
                  key="Writer"
                  value="Writer"
                  label="Penulis"
                  desc="Pilih ini jika seorang writer"
                  onClick={() => checkGroup("Writer")}
                />
              </div>
            </div>
            <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
              <button
                type="button"
                className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                onClick={() => navigate(-1)}
              >
                <span className="mr-2">
                  <HiOutlineArrowLeft size={18} />
                </span>
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              >
                <span className="mr-2">
                  <HiOutlineSave size={18} />
                </span>
                <span>Tambah Editor</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
        <thead className="border-b border-t text-gray-600 bg-blue-100">
          <tr>
            <th scope="col" className="p-2 border-r">
              #
            </th>
            <th scope="col" className="px-2 py-3 border-r">
              Foto
            </th>
            <th scope="col" className="px-2 py-3 border-r">
              <div className="flex items-center">
                Nama Lengkap
                <Link to="">
                  <FaSort />
                </Link>
              </div>
            </th>
            <th scope="col" className="px-2 py-3 border-r">
              <div className="flex items-center">
                Inisial
                <Link to="">
                  <FaSort />
                </Link>
              </div>
            </th>
            <th scope="col" className="px-2 py-3 border-r">
              <div className="flex items-center">
                Job
                <Link to="">
                  <FaSort />
                </Link>
              </div>
            </th>

            <th scope="col" className="px-2 py-3">
              <span>Aksi</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {editorteams.map((item, index) => (
            <tr key={index} className="bg-white border-b hover:bg-gray-50">
              <td className="p-2 border-r">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </td>
              <td className="px-2 py-2 text-gray-900 border-r text-center">
                <ModalImage
                  small={`${API_UPLOAD}/editor/resize/x64/${item.idEditor.fotoProfile}`}
                  large={`${API_UPLOAD}/editor/real/${item.idEditor.fotoProfile}`}
                  alt={item.createdAt}
                  hideZoom={true}
                  showRotate={true}
                  className="w-[32px] h-[32px] rounded-lg drop-shadow hover:drop-shadow-lg"
                />
              </td>
              <td className="px-2 py-2 border-r">
                <span className="font-poppins-semibold">
                  {item.idEditor.namaLengkap}
                </span>{" "}
                ({item.idEditor.group})
              </td>
              <td className="px-2 py-2 border-r">
                {item.idEditor.initialName}
              </td>
              <td className="px-2 py-2 border-r">{item.group}</td>
              <td className="px-2 py-2 text-center border-r">
                <div className="inline-flex flex-row">
                  <button type="button" onClick={() => deleteData(item._id)}>
                    <Icon
                      icon="solar:trash-bin-trash-bold-duotone"
                      style={{ fontSize: "21px", color: "#e11d48" }}
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AddEditor;
