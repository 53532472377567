import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";
import TableGrid from "../../components/TableGrid";
import Badge from "../../components/Badge";
import ProfileGrid from "../../components/ProfileGrid/ProfileGrid";

const Table = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemPage, setItemPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [field, setField] = useState("ipAddress");
  const [fieldQuery, setFieldQuery] = useState("ipAddress");
  const [keyword, setKeyword] = useState("");
  const [keywordQuery, setKeywordQuery] = useState("");

  const [tabFilter, setTabFilter] = useState("");
  const [expandedItems, setExpandedItems] = useState([]); // Track expanded items for show more/less

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL}/visitor?field=${fieldQuery}&keyword=${keywordQuery}&device=${tabFilter}&page=${page}&limit=${limit}`,
    sendRequestGet
  );

  // Function to limit text length
  const limitText = (text, maxLength) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  useEffect(() => {
    // Check token validity on page load
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const changePage = ({ selected }) => {
    setItemPage(selected);
    setPage(selected + 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeywordQuery(keyword);
    setFieldQuery(field);
  };

  const resetPage = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeyword("");
    setKeywordQuery("");
    setField("ipAddress");
    setFieldQuery("ipAddress");
    setLimit(10);
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <TableGrid
      search={{
        itemField: [
          { title: "IP Address", value: "ipAddress" },
          { title: "UA", value: "ua" },
          { title: "OS", value: "os" },
          { title: "Browser", value: "browser" },
          { title: "UrlVisit", value: "urlVisit" },
        ],
        setField: setField,
        field: field,
        keyword: keyword,
        setKeyword: setKeyword,
        submit: searchData,
      }}
      tabFilter={{
        itemTab: [
          { title: "ALL", value: "" },
          { title: "DESKTOP", value: "desktop" },
          { title: "MOBILE", value: "mobile" },
        ],
        setTab: setTabFilter,
        tabActive: tabFilter,
      }}
      toolbox={{
        reset: resetPage,
      }}
      columns={[
        "#",
        "IP ADDRESS",
        "UA",
        "DEVICE",
        "URL VISIT",
        "OS",
        "BROWSER",
        "CREATED AT",
      ]}
      data={data.docs.map((val) => {
        return [
          val._id,
          <span className="font-poppins-semibold">{val.ipAddress}</span>,
          <ProfileGrid profiles={[{ description: val.ua }]} />,
          <div className="w-20">
            <Badge
              text={val.device === "desktop" ? "DESKTOP" : "MOBILE"}
              tipe={val.device === "desktop" ? "primary" : "warning"}
            />
          </div>,
          <ProfileGrid profiles={[{ description: val.urlVisit }]} />,
          val.urlVisit && val.os ? (
            <div>
              <div>{val.os.name}</div>
              <div>({val.os.version})</div>
            </div>
          ) : (
            "-"
          ),
          val.urlVisit && val.browser ? (
            <div>
              <div>{val.browser.name}</div>
              <div>({val.browser.version})</div>
            </div>
          ) : (
            "-"
          ),
          val.createdAt,
        ];
      })}
      totalDocs={data.totalDocs}
      totalPages={data.totalPages}
      page={itemPage}
      limit={limit}
      setLimit={setLimit}
      changePage={changePage}
    />
  );
};

export default Table;
