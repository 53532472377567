import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Kategori = () => {
  return (
    <div>
      <Header
        titleHead="Kategori"
        icon="solar:notification-unread-lines-bold-duotone"
      />
      <Outlet />
    </div>
  );
};

export default Kategori;
