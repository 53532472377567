import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Visitor = () => {
  return (
    <div>
      <Header titleHead="Visitor" icon="solar:user-id-bold" />
      <Outlet />
    </div>
  );
};

export default Visitor;
