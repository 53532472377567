import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Berita = () => {
  return (
    <div>
      <Header titleHead="Berita" icon="solar:global-bold" />
      <Outlet />
    </div>
  );
};

export default Berita;
