import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Paths } from "./routes/paths";

const router = createBrowserRouter(Paths);

function App() {
  return (
    <RouterProvider
      router={router}
      future={{
        v7_partialHydration: true,
        v7_startTransition: true,
      }}
    />
  );
}

export default App;
