import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Slider = () => {
  return (
    <div>
      <Header
        titleHead="Slider"
        icon="solar:slider-minimalistic-horizontal-bold"
      />
      <Outlet />
    </div>
  );
};

export default Slider;
