import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify-icon/react";

import pl from "../../assets/images/logo-payolebarnews.png";

const Sidebar = () => {
  const menuList = [
    {
      title: "Navigation",
    },
    {
      title: "Dashboard",
      path: "/",
      icon: (
        <Icon
          icon="solar:home-smile-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Visitor",
      path: "/visitor",
      icon: (
        <Icon
          icon="solar:user-id-bold"
          global-bold
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Berita",
    },
    {
      title: "Kategori",
      path: "/kategori",
      icon: (
        <Icon
          icon="solar:notification-unread-lines-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Page",
      path: "/page",
      icon: (
        <Icon
          icon="solar:clapperboard-edit-bold"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },

    {
      title: "Editor",
      path: "/editor",
      icon: (
        <Icon
          icon="solar:user-check-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Berita",
      path: "/berita",
      icon: (
        <Icon
          icon="solar:global-bold"
          global-bold
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    // {
    //   title: "Berita Video",
    //   path: "/berita-video",
    //   icon: (
    //     <Icon
    //       icon="solar:video-frame-play-horizontal-bold-duotone"
    //       global-bold
    //       style={{ fontSize: "24px", color: "#facc15" }}
    //     />
    //   ),
    // },
    // {
    //   title: "Slider",
    //   path: "/slider",
    //   icon: (
    //     <Icon
    //       icon="solar:slider-minimalistic-horizontal-bold"
    //       global-bold
    //       style={{ fontSize: "24px", color: "#facc15" }}
    //     />
    //   ),
    // },
  ];

  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased fixed z-20">
      <div className="fixed flex flex-col top-0 left-0 w-64 bg-[#23282E] h-full border-r">
        <div className="flex flex-col items-center justify-center h-24">
          <img src={pl} className="w-[50px]" alt="logo" />
          <div className="font-sans font-bold text-sm text-white">
            PAYOLEBAR NEWS
          </div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-4 space-y-1">
            {menuList.map((menu, index) => {
              if (menu.path) {
                return (
                  <li key={`menu-${index}`}>
                    <NavLink
                      to={menu.path}
                      className={({ isActive }) =>
                        isActive
                          ? "relative flex flex-row items-center ml-1 mr-1 h-11 focus:outline-none text-orange-600 bg-[#64748b] pr-6 rounded-lg"
                          : "relative flex flex-row items-center ml-1 mr-1 h-11 focus:outline-none text-black hover:bg-[#475569] hover:text-gray-800 transition ease-in-out delay-75 duration-300 border-transparent rounded-lg"
                      }
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        {menu.icon}
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate text-slate-50">
                        {menu.title}
                      </span>
                    </NavLink>
                  </li>
                );
              } else {
                return (
                  <li key={`list-${index}`} className="px-5">
                    <div className="flex flex-row items-center h-8">
                      <div className="text-sm font-bold tracking-wide text-slate-50">
                        {menu.title}
                      </div>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
