import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineSave, HiOutlineArrowLeft } from "react-icons/hi";

import ChooseCard from "../../components/ChooseCard";
import InputText from "../../components/Form2/InputText";

import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPut } from "../../swr";
import Loading from "../Loading";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import useSWR from "swr";

const FormAdd = () => {
  const { id } = useParams();

  const [namaLengkap, setNamaLengkap] = useState("");
  const [slugPenulis, setSlugPenulis] = useState("");
  const [initialName, setInitialName] = useState("");
  const [jabatan, setJabatan] = useState("");
  const [email, setEmail] = useState("");
  const [nomorHp, setNomorHp] = useState("");
  const [noIdCard, setNoIdCard] = useState("");

  const [group, setGroup] = useState("");
  const [statusEditor, setStatusEditor] = useState("");

  const navigate = useNavigate();

  const { data, error, isLoading } = useSWR(
    `${API_URL}/editor/${id}`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/editor/${id}`,
    sendRequestPut
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    if (!isLoading && data) {
      setNamaLengkap(data.namaLengkap);
      setSlugPenulis(data.slugPenulis);
      setInitialName(data.initialName);
      setJabatan(data.jabatan);
      setEmail(data.email);
      setNomorHp(data.nomorHp);
      setNoIdCard(data.noIdCard);

      setStatusEditor(data.statusEditor);
      setGroup(data.group);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const checkStatusEditor = (selected) => {
    setStatusEditor(selected);
  };

  const checkGroup = (selected) => {
    setGroup(selected);
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        namaLengkap: namaLengkap,
        slugPenulis: slugPenulis,
        initialName: initialName,
        jabatan: jabatan,
        email: email,
        nomorHp: nomorHp,
        noIdCard: noIdCard,

        statusEditor: statusEditor,
        group: group,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Edit Editor Baru
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Perubahan editor, isi informasi data dibawah ini.
        </p>
      </div>
      <div className="grid grid-cols-6 py-6">
        <form
          onSubmit={simpan}
          className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
        >
          <div className="border-b border-gray-300 pb-2 px-4">
            <h2 className="font-sans font-bold text-lg">Detail Editor</h2>
            <p className="font-sans font-normal text-sm text-gray-400">
              Nama lengkap, slug penulis, nama inisial, foto
            </p>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
            <div className="sm:col-start-1 sm:col-span-4">
              <InputText
                label="Nama Lengkap"
                name="namaLengkap"
                val={namaLengkap}
                set={setNamaLengkap}
                placeholder="Masukkan nama lengkap"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-3">
              <InputText
                label="Slug Penulis"
                name="slugPenulis"
                val={slugPenulis}
                set={setSlugPenulis}
                placeholder="Masukkan slug penulis"
              />
              <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
                Ketik slug penulis untuk url penulis
              </p>
            </div>
            <div className="sm:col-span-3">
              <InputText
                label="Nama Inisial"
                name="initialName"
                val={initialName}
                set={setInitialName}
                placeholder="Masukkan nama inisial"
              />
              <p className="mt-1 text-xs font-sans leading-6 text-gray-300">
                Ketik nama isial dari editor atau penulis
              </p>
            </div>
            <div className="sm:col-span-3">
              <InputText
                label="Jabatan"
                name="jabatan"
                val={jabatan}
                set={setJabatan}
                placeholder="Masukkan jabatan"
              />
            </div>
            <div className="sm:col-span-3">
              <InputText
                label="Email"
                name="email"
                val={email}
                set={setEmail}
                placeholder="Masukkan email"
              />
            </div>
            <div className="sm:col-span-3">
              <InputText
                label="Nomor HP"
                name="nomorHp"
                val={nomorHp}
                set={setNomorHp}
                placeholder="0822 xxx"
              />
            </div>
            <div className="sm:col-span-3">
              <InputText
                label="Nomor ID Card"
                name="noIdCard"
                val={noIdCard}
                set={setNoIdCard}
                placeholder="xxxx"
              />
            </div>

            <div className="sm:col-start-1 sm:col-span-3">
              <ChooseCard
                name={statusEditor}
                key="PERS"
                value="PERS"
                label="Pers"
                desc="Pilih ini jika editor yang di tambahkan merupakan Pers"
                onClick={() => checkStatusEditor("PERS")}
              />
            </div>
            <div className="sm:col-span-3">
              <ChooseCard
                name={statusEditor}
                key="NON-PERS"
                value="NON-PERS"
                label="Non-Pers"
                desc="Pilih ini jika editor yang di tambahkan merupakan Non-Pers"
                onClick={() => checkStatusEditor("NON-PERS")}
              />
            </div>

            <div className="sm:col-start-1 sm:col-span-3">
              <ChooseCard
                name={group}
                key="Writer"
                value="Writer"
                label="Penulis"
                desc="Pilih ini jika editor yang di tambahkan merupakan penulis"
                onClick={() => checkGroup("Writer")}
              />
            </div>
            <div className="sm:col-span-3">
              <ChooseCard
                name={group}
                key="Editor"
                value="Editor"
                label="Editor"
                desc="Pilih ini jika editor yang di tambahkan merupakan editor
                  tulisan"
                onClick={() => checkGroup("Editor")}
              />
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiOutlineArrowLeft size={18} />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiOutlineSave size={18} />
              </span>
              <span>Simpan Editor</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAdd;
