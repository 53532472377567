import React from "react";
import { API_URL } from "../../config";
import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import { Link } from "react-router-dom";

const TableTopBerita = () => {
  const { data, error, isLoading } = useSWR(
    `${API_URL}/dashboard/top-berita`,
    sendRequestGet
  );

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      <div className="container mx-auto mt-10">
        <h3 className="font-bold my-4">TOP BERITA</h3>
        <div className="overflow-y-auto h-[450px]">
          <table className="min-w-full border border-gray-200 bg-white text-sm">
            <thead className="bg-gray-100 text-left">
              <tr>
                <th className="px-4 py-2 border-b">#</th>
                <th className="px-4 py-2 border-b">JUDUL BERITA</th>
                <th className="px-4 py-2 border-b">VIEWER</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={item.id}
                  className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                >
                  <td className="px-4 py-2 border-b">{index + 1}</td>
                  <td className="px-4 py-2 border-b">
                    <Link
                      to={`https://payolebarnews.com/read/${item.slugKategori}/${item.slug}`}
                      className="font-normal hover:text-blue-500"
                      target="_blank"
                    >
                      {item.title}
                    </Link>
                  </td>
                  <td className="px-4 py-2 border-b text-center">
                    {item.countViews}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableTopBerita;
