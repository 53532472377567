// import React from "react";

// const FbShare = (url) => {
//   React.useEffect(() => {
//     (function (d, s, id) {
//       var js,
//         fjs = d.getElementsByTagName(s)[0];
//       if (d.getElementById(id)) return;
//       js = d.createElement(s);
//       js.id = id;
//       js.src = "https://connect.facebook.net/en_US/sdk.js";
//       fjs.parentNode.insertBefore(js, fjs);
//     })(document, "script", "facebook-jssdk");

//     window.fbAsyncInit = function () {
//       window.FB.init({
//         appId: "2659853187545727",
//         autoLogAppEvents: true,
//         xfbml: true,
//         version: "v21.0",
//       });
//     };
//   }, []);

//   window.FB.ui(
//     {
//       method: "share",
//       href: url,
//     },
//     (response) => {
//       if (response && !response.error) {
//         console.log("success");
//       } else {
//         console.log("error");
//       }
//     }
//   );
// };

// export default FbShare;

import React, { useEffect, useState, useCallback } from "react";

const FbShare = (url) => {
  const [isSdkReady, setIsSdkReady] = useState(false);

  useEffect(() => {
    // Load Facebook SDK only if it's not already loaded
    const loadFacebookSDK = () => {
      if (document.getElementById("facebook-jssdk")) {
        console.log("SDK Facebook sudah ada di dokumen.");
        return;
      }

      const js = document.createElement("script");
      js.id = "facebook-jssdk";
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.onload = () => {
        console.log("Script SDK Facebook berhasil dimuat.");
      };
      js.onerror = () => {
        console.error("Gagal memuat script SDK Facebook.");
      };
      document.body.appendChild(js);
    };

    loadFacebookSDK();

    // Menginisialisasi SDK Facebook
    window.fbAsyncInit = function () {
      console.log("Menginisialisasi SDK Facebook...");
      window.FB.init({
        appId: "2659853187545727", // Ganti dengan App ID Facebook Anda
        autoLogAppEvents: true,
        xfbml: true,
        version: "v21.0",
      });
      console.log("SDK Facebook berhasil diinisialisasi.");
      setIsSdkReady(true); // Mengubah state menjadi true setelah SDK siap
    };
  }, []);

  const shareToFacebook = useCallback(() => {
    // Pastikan SDK sudah siap sebelum berbagi
    if (!isSdkReady) {
      console.error("SDK Facebook belum dimuat atau diinisialisasi.");
      return;
    }

    if (window.FB) {
      console.log("Memulai proses berbagi ke Facebook...");
      window.FB.ui(
        {
          method: "feed",
          link: url,
          caption: "Contoh caption",
          description: "Deskripsi berbagi",
        },
        (response) => {
          if (response && !response.error) {
            console.log("Berhasil berbagi ke Facebook:", response);
          } else {
            console.error("Gagal berbagi ke Facebook:", response);
            if (response.error) {
              console.error("Error details:", response.error);
            }
          }
        }
      );
    } else {
      console.error("window.FB tidak tersedia.");
    }
  }, [isSdkReady, url]);

  return { shareToFacebook, isSdkReady }; // Mengembalikan shareToFacebook dan isSdkReady
};

export default FbShare;
