import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Editor = () => {
  return (
    <div>
      <Header titleHead="Editor" icon="solar:user-check-bold-duotone" />
      <Outlet />
    </div>
  );
};

export default Editor;
