import React from "react";

const Badge = (props) => {
  let bgBadge = "bg-emerald-100 text-emerald-600";
  if (props.tipe === "danger") {
    bgBadge = "bg-red-600 text-red-100";
  } else if (props.tipe === "warning") {
    bgBadge = "bg-orange-600 text-orange-100";
  } else if (props.tipe === "primary") {
    bgBadge = "bg-blue-600 text-blue-100";
  } else if (props.tipe === "green") {
    bgBadge = "bg-green-600 text-green-100";
  }

  return (
    <span
      className={`pt-1 pl-2 pr-2 pb-1 font-sans text-[10px] rounded-md ${bgBadge}`}
    >
      {props.text}
    </span>
  );
};

export default Badge;
