import React from "react";

const FbComment = (props) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.FB) {
        window.FB.XFBML.parse();
        setLoading(false);
      } else {
        loadFacebookSDK();
        const checkFB = setInterval(() => {
          if (window.FB) {
            window.FB.XFBML.parse();
            setLoading(false);
            clearInterval(checkFB);
          }
        }, 100);
      }
    }
  }, [props.url]);

  const loadFacebookSDK = () => {
    if (!window.FB) {
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      script.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v21.0&appId=2659853187545727&autoLogAppEvents=1`;
      document.body.appendChild(script);
    }
  };

  return (
    <>
      {loading && (
        <div className="text-center mt-5 mx-0 mb-0">
          <p className="text-blue-600 font-sans text-center text-sm">
            Loading comments...
          </p>
        </div>
      )}
      <div className="w-full">
        <div
          className="fb-comments w-full"
          data-href={props.url}
          data-width="100%"
          data-numposts="10"
          data-moderate="true"
          data-lazy={loading}
          style={{ display: loading ? "none" : "block" }}
        ></div>
      </div>
    </>
  );
};

export default FbComment;
