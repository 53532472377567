import React, { useEffect, useState } from "react";
import useSWR from "swr";
import Modal from "../../components/Modal";
import FbComment from "./FbComment";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";

const fetcher = (url) => fetch(url).then((res) => res.json());

const FacebookCommentCount = ({ url }) => {
  const [openModal, setOpenModal] = useState(false);

  const appId = "2659853187545727";
  const appSecret = "eeb3aef1ef6a0ee610e103c7142d81c7";
  const accessToken = `${appId}|${appSecret}`;
  const apiUrl = `https://graph.facebook.com/v21.0/?id=${url}&fields=engagement&access_token=${accessToken}`;

  const { data, error, isValidating } = useSWR(apiUrl, fetcher, {
    revalidateOnFocus: false, // Prevent revalidation when tab/window regains focus
    dedupingInterval: 3600000, // Cache data for 1 hour
  });

  if (error) return <div>Error fetching comments: {error.message}</div>;
  if (!data) return <div>Loading comments...</div>;

  const { engagement } = data;
  const likeCount = engagement?.reaction_count || 0;
  const shareCount = engagement?.share_count || 0;
  const commentCount = engagement?.comment_count || 0;

  return (
    <div>
      <p>
        <span
          className="hover:text-blue-700 cursor-pointer transition duration-300 ease-in-out flex items-center"
          onClick={() => setOpenModal(true)}
        >
          <span>
            Comments: {isValidating ? "Updating..." : commentCount}, Likes:{" "}
            {isValidating ? "Updating..." : likeCount}, Share:
            {isValidating ? "Updating..." : shareCount}
          </span>
          <Icon
            icon="nimbus:external-link"
            style={{ fontSize: "15px", color: "blue", marginLeft: "5px" }}
          />
        </span>
      </p>

      <Modal
        title="KOMENTAR FACEBOOK"
        active={openModal}
        setClose={() => setOpenModal(false)}
        content={
          <>
            <FbComment url={url} />
          </>
        }
      />
    </div>
  );
};

export default FacebookCommentCount;
