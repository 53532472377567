import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Page = () => {
  return (
    <div>
      <Header titleHead="Page" icon="solar:clapperboard-edit-bold" />
      <Outlet />
    </div>
  );
};

export default Page;
