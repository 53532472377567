import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineSave, HiOutlineArrowLeft } from "react-icons/hi";

import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPut } from "../../swr";
import Loading from "../Loading";

import InputText from "../../components/Form2/InputText";
import TextArea from "../../components/Form2/TextArea";
import ChooseCard from "../../components/ChooseCard";
import useSWR from "swr";
import Select from "../../components/Form2/Select";
import ErrorAccess from "../ErrorPage/ErrorAccess";

const FormEdit = () => {
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [shortPost, setShortPost] = useState("");
  const [hashTag, setHashTag] = useState("");
  const [statusPost, setStatusPost] = useState("Unpublished");
  const [headline, setHeadline] = useState("Tidak");
  const [redaksi, setRedaksi] = useState("Tidak");
  const [post, setPost] = useState(() => EditorState.createEmpty());
  const [fotoSumber, setFotoSumber] = useState("");

  const [idKategori, setIdKategori] = useState(null);

  const navigate = useNavigate();

  const { data, error, isLoading } = useSWR(
    `${API_URL}/berita/${id}`,
    sendRequestGet
  );

  const { data: dataKategori, error: errorKategori } = useSWR(
    `${API_URL}/kategori-select`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL}/berita/${id}`,
    sendRequestPut
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    if (!isLoading && data) {
      setTitle(data.title);
      setShortPost(data.shortPost);
      setPost(data.post);
      setHashTag(data.hashTag);
      setFotoSumber(data.fotoSumber);
      setStatusPost(data.statusPost);
      setHeadline(data.headline);
      setRedaksi(data.redaksi);
      setIdKategori(data.idKategori);
    }

    if (data?.post) {
      const html = data.post;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setPost(EditorState.createWithContent(contentState));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const checkGroup = (selected) => {
    setStatusPost(selected);
  };

  const selectedKategori = dataKategori?.find(
    (item) => item._id === idKategori
  );

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        title: title,
        shortPost: shortPost,
        post: draftToHtml(convertToRaw(post.getCurrentContent())),
        hashTag: hashTag,
        statusPost: statusPost,
        headline: headline,
        redaksi: redaksi,
        fotoSumber: fotoSumber,

        idKategori: idKategori,
        namaKategori: selectedKategori?.namaKategori || "",
        slugKategori: selectedKategori?.slugKategori || "",
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating) return <Loading />;

  if (error || errorKategori) return <ErrorAccess />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Edit Berita Baru
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Perubahan berita, isi informasi data dibawah ini.
        </p>
      </div>
      <div className="grid grid-cols-6 py-6">
        <form
          onSubmit={simpan}
          className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
        >
          <div className="border-b border-gray-300 pb-2 px-4">
            <h2 className="font-sans font-bold text-lg">Detail Berita</h2>
            <p className="font-sans font-normal text-sm text-gray-400">
              Judul berita, kategori, isi berita, tag, sumber foto
            </p>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <InputText
                label="Judul Berita"
                name="title"
                val={title}
                set={setTitle}
                placeholder="Masukkan judul berita"
              />
            </div>

            <div className="sm:col-start-1 sm:col-span-6">
              <Select
                label="Kategori"
                name="idKategori"
                val={idKategori || ""}
                set={setIdKategori}
                item={
                  dataKategori?.map((item) => ({
                    value: item._id,
                    name: item.namaKategori,
                  })) || []
                }
              />
            </div>

            <div className="sm:col-start-1 sm:col-span-3">
              <ChooseCard
                name={statusPost}
                key="Unpublished"
                value="Unpublished"
                label="Unpublished"
                desc="Pilih ini jika berita ingin unpublished"
                onClick={() => checkGroup("Unpublished")}
              />
            </div>
            <div className="sm:col-span-3">
              <ChooseCard
                name={statusPost}
                key="Published"
                value="Published"
                label="Publish"
                desc="Pilih ini jika berita ingin di publish"
                onClick={() => checkGroup("Published")}
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <label
                htmlFor={post}
                className="block font-poppins-regular text-xs leading-6 text-gray-400"
              >
                Isi Berita
              </label>
              <Editor
                editorState={post}
                onEditorStateChange={setPost}
                wrapperClassName="border border-slate-200 rounded-lg"
                editorClassName="bg-gray-100 px-4 font-poppins-regular"
                toolbarClassName="rounded-lg"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <TextArea
                label="Short Post"
                name="shortPost"
                val={shortPost}
                set={setShortPost}
                rows={4}
                placeholder="Masukkan isi berita singkat"
              />
              <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
                Ketik isi berita yang singkat
              </p>
            </div>
            <div className="sm:col-span-6">
              <InputText
                label="Hash Tag"
                name="hashTag"
                val={hashTag}
                set={setHashTag}
                placeholder="Masukkan hash tag, gunakan koma (,) untuk pemisah setiap tag"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <InputText
                label="Sumber Foto"
                name="fotoSumber"
                val={fotoSumber}
                set={setFotoSumber}
                placeholder="Masukkan keterangan asal foto"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-2">
              <label
                htmlFor="headline"
                className="block font-poppins-regular text-xs leading-6 text-gray-400"
              >
                Headline
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="headline"
                  id="headline"
                  value="Ya"
                  className="sr-only peer"
                  checked={headline === "Ya" ? true : false}
                  onChange={(e) =>
                    e.target.checked ? setHeadline("Ya") : setHeadline("Tidak")
                  }
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-poppins-medium text-gray-900">
                  {headline}
                </span>
              </label>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="redaksi"
                className="block font-poppins-regular text-xs leading-6 text-gray-400"
              >
                Redaksi
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="redaksi"
                  id="redaksi"
                  value="Ya"
                  className="sr-only peer"
                  checked={redaksi === "Ya" ? true : false}
                  onChange={(e) =>
                    e.target.checked ? setRedaksi("Ya") : setRedaksi("Tidak")
                  }
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-poppins-medium text-gray-900 ">
                  {redaksi}
                </span>
              </label>
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiOutlineArrowLeft size={18} />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiOutlineSave size={18} />
              </span>
              <span>Simpan Berita</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormEdit;
