import React, { useState } from "react";

const truncLength = 35;

const ProfileGridItem = ({ name, description = "" }) => {
  const [isCollapse, setIsCollapse] = useState(false);

  // Toggle the show more/less functionality
  const handleToggle = () => {
    setIsCollapse(!isCollapse);
  };

  return (
    <div className="profile-grid-item">
      <p className="font-bold">{name}</p>
      <p>
        {/* If collapsed, truncate the text */}
        {description.length > truncLength && !isCollapse
          ? `${description.substring(0, truncLength)}...`
          : description}
        {/* Button to toggle collapse */}
        {description.length > truncLength && (
          <button
            onClick={handleToggle}
            className="text-blue-500 hover:text-blue-700 ml-2"
          >
            {isCollapse ? "Read Less" : "Read more"}
          </button>
        )}
      </p>
    </div>
  );
};

export default ProfileGridItem;
