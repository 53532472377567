import React from "react";
import ProfileGridItem from "./ProfileGridItem";

const ProfileGrid = ({ profiles }) => (
  <div className="profile-grid">
    {profiles && profiles.length > 0 ? (
      profiles.map((profile, index) => (
        <ProfileGridItem key={index} {...profile} />
      ))
    ) : (
      <p>No profiles available</p>
    )}
  </div>
);

export default ProfileGrid;
