import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import jwtDecode from "jwt-decode";
import { API_URL } from "../../config/index";
import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import {
  HiGlobeAlt,
  HiClipboardList,
  HiArchive,
  HiViewGridAdd,
} from "react-icons/hi";
import Loading from "../Loading";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import TableTopBerita from "./TableTopBerita";
import TableTopTopic from "./TableTopTopic";

const Home = () => {
  const navigate = useNavigate();
  const [namaUser, setNamaUser] = useState("");

  const { data, error, isLoading } = useSWR(
    `${API_URL}/total-data`,
    sendRequestGet
  );

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      } else {
        setNamaUser(decoded.namaUser);
      }
    } else {
      navigate("/login");
    }
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div>
      <Header titleHead="Home" icon="solar:graph-new-up-bold-duotone" />
      <div className="p-4 ">
        <p className="font-sans text-sm mb-10">
          Selamat Datang, <strong>{namaUser}</strong>!
        </p>
        {/* grid menu */}
        <div className="grid grid-cols-4 mt-2 gap-2 h-32">
          <div className="grid grid-cols-1 gap-3 bg-gradient-to-r from-blue-400 to-blue-600 rounded-lg">
            <div className="col-span-3 lg:col-span-3 rounded-lg shadow p-4">
              <div className="flex lg:flex-row flex-col items-center bottom-5">
                <div className="">
                  <HiGlobeAlt size={50} color="#facc15" />
                </div>
                <div className="lg:ml-auto max-sm:mt-2">
                  <h1 className="font-poppins-semibold text-white text-sm lg:text-right lg:text-2xl">
                    {isLoading ? "0" : data?.berita || ""}
                  </h1>
                  <p className="font-poppins-regular text-xl text-white lg:text-right">
                    TOTAL BERITA
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-3 bg-gradient-to-r from-orange-400 to-orange-600 rounded-lg">
            <div className="col-span-3 lg:col-span-3 rounded-lg shadow p-4">
              <div className="flex lg:flex-row flex-col items-center bottom-5">
                <div className="">
                  <HiClipboardList size={50} color="#facc15" />
                </div>
                <div className="lg:ml-auto max-sm:mt-2">
                  <h1 className="font-poppins-semibold text-white text-sm lg:text-right lg:text-2xl">
                    {isLoading ? "0" : data?.kategori || ""}
                  </h1>
                  <p className="font-poppins-regular text-xl text-white lg:text-right">
                    TOTAL KATEGORI
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-3 bg-gradient-to-r from-green-400 to-green-600 rounded-lg">
            <div className="col-span-3 lg:col-span-3 rounded-lg shadow p-4">
              <div className="flex lg:flex-row flex-col items-center bottom-5">
                <div className="">
                  <HiArchive size={50} color="#facc15" />
                </div>
                <div className="lg:ml-auto max-sm:mt-2">
                  <h1 className="font-poppins-semibold text-white text-sm lg:text-right lg:text-2xl">
                    {isLoading ? "0" : data?.page || ""}
                  </h1>
                  <p className="font-poppins-regular text-xl text-white lg:text-right">
                    TOTAL PAGE
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-3 bg-gradient-to-r from-rose-400 to-rose-600 rounded-lg">
            <div className="col-span-3 lg:col-span-3 rounded-lg shadow p-4">
              <div className="flex lg:flex-row flex-col items-center bottom-5">
                <div className="">
                  <HiViewGridAdd size={50} color="#facc15" />
                </div>
                <div className="lg:ml-auto max-sm:mt-2">
                  <h1 className="font-poppins-semibold text-white text-sm lg:text-right lg:text-2xl">
                    {isLoading ? "0" : data?.visitor || ""}
                  </h1>
                  <p className="font-poppins-regular text-xl text-white lg:text-right">
                    TOTAL VISITOR
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2">
          <div>
            <TableTopBerita />
          </div>
          <div>
            <TableTopTopic />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
